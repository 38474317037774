import { useContext } from "react";
import BigCard from "../../components/Card/BigCard";
import Card from "../../components/Card/Card";
import { ArticlesContext } from "../../App";
import { getDate } from "../Article/utils/getDate";
import LoadingHome from "../Loading/LoadingHome";
import LongCard from "../../components/Card/LongCard";
import { Link } from "react-router-dom";
import CategoryLink from "../../components/Link/CategoryLink";
import { getCategoriesNames } from "../../components/utils/getCategoriesNames";
import { generateSlug } from "../../components/Card/utils/generateSlug";

const Home: React.FC = () => {
  const data = useContext(ArticlesContext);
  if (!data?.articles || !data?.categories || !data?.articles.length || !data?.categories.length) return <LoadingHome />;

  const articles = data.articles;
  const categories = data.categories;

  const highlightedData = articles.filter((e: any) => e.highlighted === 1).slice(0, 3);
  return (
    <div className="mt-10">
      <div className="container w-11/12 mx-auto">
        <BigCard />
        {highlightedData.length > 0 && (
          <>
            <h1 className="mt-5 text-3xl font-garamond text-green font-bold inline-block">A la une</h1>
            <div className="bg-green h-0.5 w-20 inline-block relative bottom-1 left-2"></div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-5">
              {highlightedData.map((article: Article) => (
                <Card
                  key={article.id}
                  title={article.title}
                  thumbnail={article.thumbnail}
                  author={article.authors.map((e: Author) => e.name).join(", ")}
                  date={getDate(article.created_at)}
                  review={article.review}
                />
              ))}
            </div>
          </>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-8">
          <div className="col-span-2">
            <h1 className="mb-8 text-3xl font-garamond text-green font-bold inline-block">Les derniers articles</h1>
            <div className="bg-green h-0.5 w-20 inline-block relative bottom-1 left-2"></div>
            <div>
              {articles.slice(0, 10).map((article: any, index: number) => {
                return (
                  <div key={index}>
                    <LongCard
                      title={article.title}
                      thumbnail={article.thumbnail}
                      author={article.authors.map((e: Author) => e.name).join(", ")}
                      date={getDate(article.created_at)}
                      categories={getCategoriesNames(categories, article.categories)}
                      review={article.review}
                    />
                    {index !== articles.slice(0, 10).length - 1 && (
                      <div className="w-full md:w-11/12 h-0.5 bg-green mb-4 opacity-50"></div>
                    )}
                  </div>
                );
              })}
              <Link to={"/all-articles"}>
                <div className="w-full md:w-11/12 bg-green py-2 rounded-md transition-all duration-150 hover:md:opacity-80">
                  <p className="text-center text-white font-medium text-base">Voir plus</p>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-span-2 md:col-span-1">
            <h1 className="mb-8 text-3xl font-garamond text-green font-bold inline-block">Nos catégories</h1>
            <div className="bg-green h-0.5 w-20 inline-block relative bottom-1 left-2"></div>
            <div className="bg-beige px-4 py-2 rounded-md">
              {categories
                .filter((category: Category) => !category.subcategory)
                .map((category: Category, index: number) => (
                  <div key={index}>
                    <CategoryLink name={category.name} url={"/all-articles?category=" + generateSlug(category.name)} />
                    {index !== categories.filter((category: Category) => !category.subcategory).length - 1 && (
                      <div className="w-full h-0.5 bg-green opacity-20"></div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
