type Props = {
  onClick: () => void;
  text: string;
  icon: string;
  square?: boolean;
};

const ShareButtonDesktop: React.FC<Props> = ({ onClick, text, icon, square = true }) => {
  return (
    <div
      className={
        (square ? "w-8" : "w-fit") +
        " md:mx-1 h-8 bg-green text-beige px-2 leading-8 text-sm cursor-pointer mb-2 mx-auto rounded-sm"
      }
      onClick={onClick}
    >
      {text}
      <i className={icon}></i>
    </div>
  );
};

export default ShareButtonDesktop;
