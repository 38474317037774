import { Link } from "react-router-dom";
import { generateSlug } from "./utils/generateSlug";
import ReviewBadge from "../Badge/ReviewBadge";

type Props = {
  title: string;
  thumbnail: string;
  onClick: () => void;
  review: Review | null;
};

const ArticleCardForMenu: React.FC<Props> = ({ title, thumbnail, onClick, review }) => {
  return (
    <Link
      to={generateSlug(title)}
      className="cursor-pointer mr-2 w-60 h-40 transition-all duration-150 hover:md:opacity-80"
      title={title}
      onClick={onClick}
    >
      <div
        className="border-2 border-green bg-beige bg-cover bg-center h-40 rounded-md relative"
        style={{ backgroundImage: 'url("' + thumbnail + '")' }}
      >
        {review && <ReviewBadge mark={review.mark} />}
      </div>
      <div>
        <h1 className={"font-garamond text-green font-bold text-xl pr-2 py-2 text-ellipsis overflow-hidden truncate"}>{title}</h1>
      </div>
    </Link>
  );
};

export default ArticleCardForMenu;
