import { Link } from "react-router-dom";

type Props = {
  name: string;
  link: string;
  active: boolean;
};

const NavItem: React.FC<Props> = ({ name, link, active }) => {
  return (
    <Link to={link} className="w-fit block sm:inline-block mr-8 my-2 md:my-0 transition-all duration-150 hover:md:opacity-80">
      <div className="w-fit">
        <p className={"uppercase text-sm text-beige font-garamond tracking-[0.2em]"}>{name}</p>
        {active ? <div className={"bg-beige h-0.5 mt-1 rounded-full"}></div> : <div className={"bg-green h-0.5 mt-1"}></div>}
      </div>
    </Link>
  );
};

export default NavItem;
