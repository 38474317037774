import { generateSlug } from "../../../components/Card/utils/generateSlug";

export const filterArticles = (search: string, author: string, category: string, data: Article[], allCategories: Category[]) => {
  return data
    .filter(
      (d: Article) =>
        search === "" ||
        d.title
          .replaceAll(" ", "")
          .toLowerCase()
          .normalize("NFD")
          .replaceAll(/[\u0300-\u036f]/g, "")
          .includes(
            search
              .replaceAll(" ", "")
              .toLowerCase()
              .normalize("NFD")
              .replaceAll(/[\u0300-\u036f]/g, "")
          )
    )
    .filter((d: Article) => {
      const authors: string[] = d.authors.map((a: Author) => generateSlug(a.name));
      return author === "S_ALL" || authors.includes(author);
    })
    .filter(
      (d: Article) =>
        category === "S_ALL" ||
        allCategories
          .filter((cat: Category) => d.categories.includes(cat.id))
          .map((e: { name: string }) => generateSlug(e.name))
          .includes(category)
    );
};
