export const getUniqueAuthors = (data: Article[]) => {
  const uniqueNames: string[] = [];
  data.forEach((obj: Article) => {
    // Check if the author is already in the array
    const authors: string[] = obj.authors.map((e: Author) => e.name);
    authors.forEach((e: string) => {
      if (!uniqueNames.includes(e)) {
        uniqueNames.push(e);
      }
    });
  });
  return uniqueNames;
};
