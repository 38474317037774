type Props = {
  title: string;
  description: string;
  mark: number;
  positivePoints: any[];
  negativePoints: any[];
};

const Review: React.FC<Props> = ({ title, description, mark, positivePoints, negativePoints }) => {
  return (
    <div className="mt-8 border-2 border-green max-w-4xl mx-auto rounded-md">
      <div className="bg-green">
        <h1 className="font-garamond font-bold text-light-beige text-2xl p-2 text-center">Résumé de l'analyse</h1>
      </div>
      <div className="p-4">
        <p className="text-green font-bold text-xl">{title}</p>
        <p className="text-green">{description}</p>
      </div>
      <div className="bg-beige py-2">
        <p className="text-green font-bold text-xl text-center">Note globale</p>
        <p className="text-green text-2xl text-center">{mark}/20</p>
      </div>
      <div className="p-4">
        {positivePoints.length > 0 && (
          <div className="mb-4">
            <p className="text-green font-bold text-xl">Points positifs</p>
            {positivePoints.map((e, i) => (
              <p key={i} className="text-green">
                <i className="fa-solid fa-circle-plus text-xs relative bottom-0.5 text-lime-600 mr-2"></i> {e.value}
              </p>
            ))}
          </div>
        )}
        {negativePoints.length > 0 && (
          <div className="">
            <p className="text-green font-bold text-xl">Points négatifs</p>
            {negativePoints.map((e, i) => (
              <p key={i} className="text-green">
                <i className="fa-solid fa-circle-minus text-xs relative bottom-0.5 text-red-500 mr-2"></i> {e.value}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Review;
