export const generateSubCategoriesList = (categories: Category[]): Category[] => {
  // We loop through the categories.
  // If a category is set as a subcategory, we add it to the previous category as a child.
  // We return the modified categories array.
  return categories
    .map((category, index) => {
      if (category.subcategory) {
        // While the parent is a subcategory, we go back to the previous category.
        let parentCategory = categories[index - 1];
        while (parentCategory && parentCategory.subcategory) {
          parentCategory = categories[categories.indexOf(parentCategory) - 1];
        }

        if (parentCategory) {
          if (parentCategory.childs) {
            if (!parentCategory.childs.find((child) => child.id === category.id)) {
              parentCategory.childs.push(category);
            }
          } else {
            parentCategory.childs = [category];
          }
        }
      }
      return category;
    })
    .filter((category) => !category.subcategory);
};
