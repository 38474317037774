import { generateSlug } from "../../../components/Card/utils/generateSlug";
import CustomImage from "../CustomImage";
import Delimiter from "../Delimiter";
import List from "../List";
import Quote from "../Quote";
import Table from "../Table";

export const generateArticleContent = (article: any) => {
  switch (article.type) {
    case "header":
      switch (article.data.level) {
        case "1":
        case 1:
          return (
            <h1 id={generateSlug(article.data.text)} className="text-green text-5xl font-garamond font-bold mt-6 mb-8">
              {article.data.text.replace("&nbsp;", " ")}
            </h1>
          );
        case "2":
        case 2:
          return (
            <h2 id={generateSlug(article.data.text)} className="text-green text-4xl font-garamond font-bold mt-6 mb-8">
              {article.data.text.replace("&nbsp;", " ")}
            </h2>
          );
        case "3":
        case 3:
          return (
            <h3 id={generateSlug(article.data.text)} className="text-green text-3xl font-garamond font-bold mt-6 mb-8">
              {article.data.text.replace("&nbsp;", " ")}
            </h3>
          );
        case "4":
        case 4:
          return (
            <h4 id={generateSlug(article.data.text)} className="text-green text-2xl font-garamond font-bold mt-6 mb-8">
              {article.data.text.replace("&nbsp;", " ")}
            </h4>
          );
        case "5":
        case 5:
          return (
            <h5 id={generateSlug(article.data.text)} className="text-green text-xl font-garamond font-bold mt-6 mb-8">
              {article.data.text.replace("&nbsp;", " ")}
            </h5>
          );
        case "6":
        case 6:
          return (
            <h6 id={generateSlug(article.data.text)} className="text-green text-lg font-garamond font-bold mt-6 mb-8">
              {article.data.text.replace("&nbsp;", " ")}
            </h6>
          );
      }
      break;
    case "paragraph":
      return <p className="mb-2" dangerouslySetInnerHTML={{ __html: article.data.text }}></p>;
    case "quote":
      return <Quote content={article.data.text} author={article.data.caption} />;
    case "list":
      return <List style={article.data.style} items={article.data.items} />;
    case "delimiter":
      return <Delimiter />;
    case "table":
      return <Table withHeadings={article.data.withHeadings} content={article.data.content} />;
    case "image":
      return (
        <CustomImage
          url={article.data.file.url}
          caption={article.data.caption}
          stretched={article.data.stretched}
          withBackground={article.data.withBackground}
          withBorder={article.data.withBorder}
        />
      );
  }
};
