type Props = {
    content:string;
    author:string;
}

const Quote:React.FC<Props> = ({content, author}) => {

    const quoteContent = content.replace('<br>', '').replace('<b>', '').replace('<b/>', '').replace('<i>', '').replace('</i>', '');
    const quoteAuthor = author.replace('<br>', '').replace('<b>', '').replace('<b/>', '').replace('<i>', '').replace('</i>', '');

    return (
        <div className="w-full my-8">
            <p className="text-center text-light-green opacity-80 text-2xl"><i className="fa-solid fa-quote-left text-sm relative bottom-3"></i> {quoteContent} <i className="fa-solid fa-quote-right text-sm relative top-3"></i></p>
            <p className="text-center text-light-green opacity-80 mt-4 italic pl-40">- {quoteAuthor}</p>
        </div>
    )
}

export default Quote;