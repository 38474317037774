const LoadingHome: React.FC = () => {
    return (
        <div className="my-10">
            <div className="container w-11/12 mx-auto">
                <div className="border-4 border-beige min-h-[240px] bg-beige overflow-hidden relative bg-cover bg-center pb-10 md:pb-0 animate-pulse"></div>
                <div className="mt-5 bg-beige w-52 h-8 animate-pulse"></div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-5">
                    <div className="break-inside-avoid-column animate-pulse">
                        <div className="border-4 border-beige h-60 bg-beige bg-cover bg-center"></div>
                        <div className="mt-4">
                            <div className="w-20 h-4 bg-beige px-2 mb-4"></div>
                            <div className="w-60 h-4 bg-beige px-2"></div>
                        </div>
                    </div>
                    <div className="break-inside-avoid-column animate-pulse">
                        <div className="border-4 border-beige h-60 bg-beige bg-cover bg-center"></div>
                        <div className="mt-4">
                            <div className="w-20 h-4 bg-beige px-2 mb-4"></div>
                            <div className="w-60 h-4 bg-beige px-2"></div>
                        </div>
                    </div>
                    <div className="break-inside-avoid-column animate-pulse">
                        <div className="border-4 border-beige h-60 bg-beige bg-cover bg-center"></div>
                        <div className="mt-4">
                            <div className="w-20 h-4 bg-beige px-2 mb-4"></div>
                            <div className="w-60 h-4 bg-beige px-2"></div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-8">
                    <div className="col-span-2">
                        <div className="mt-5 bg-beige w-80 h-8 animate-pulse"></div>
                        <div className="">
                            <div className="mt-5 bg-beige w-full h-20 animate-pulse"></div>
                            <div className="mt-5 bg-beige w-full h-20 animate-pulse"></div>
                            <div className="mt-5 bg-beige w-full h-20 animate-pulse"></div>
                            <div className="mt-5 bg-beige w-full h-20 animate-pulse"></div>
                        </div>
                    </div>
                    <div>
                        <div className="mt-5 bg-beige w-80 h-8 animate-pulse"></div>
                        <div className="mt-5 bg-beige w-full h-16 animate-pulse"></div>
                        <div className="mt-5 bg-beige w-full h-16 animate-pulse"></div>
                        <div className="mt-5 bg-beige w-full h-16 animate-pulse"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoadingHome;