import React, { useEffect, useState } from "react";
import { useRef } from "react";

type SimpleSelectorProps = {
  /**
   * An array with the data that will be displayed
   */
  options: DropdownOption[];
  /**
   * The selected element
   */
  selectedOption: DropdownOption;
  /**
   * Should display the search bar ?
   */
  searchable?: boolean;
  /**
   * The function that will be called when the user selects an option
   */
  onChange: (e: string) => void;
  /**
   * Custom CSS properties
   */
  className?: string;
  /**
   * Default label displayed
   */
  defaultLabel: string;
};

// Note: If you want to personalize in depth, you can use the `c-ui-selector` class to customize the dropdown CSS

const Dropdown: React.FC<SimpleSelectorProps> = ({
  options,
  searchable = false,
  onChange,
  selectedOption,
  className,
  defaultLabel,
}) => {
  const [showSelector, setShowSelector] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);

  // When the user types on the search bar, the filter will be updated
  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  // When the user clicks outside the selector, the dropdown will be hidden
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowSelector(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [setShowSelector]);

  // When the user selects an option, the dropdown will be hidden and the onChange function will be called
  const onOptionClick = (option: string) => {
    setShowSelector(false);
    onChange(option);
  };

  return (
    <div className="relative" ref={ref}>
      <div
        onClick={() => setShowSelector(!showSelector)}
        className={
          "block w-full cursor-pointer border-2 border-solid border-green rounded-md px-2 py-1 text-sm h-11 text-green bg-white font-bold " +
          className
        }
      >
        <i className={(showSelector ? "fa-chevron-up" : "fa-chevron-down") + " fa-solid absolute right-2 top-4 text-[10px]"}></i>
        <div
          className={
            (selectedOption.value === "" ? "italic" : "") + " select-none text-left text-xs mb-0 max-w-[85%] overflow-x-hidden"
          }
        >
          {selectedOption.value === "" ? defaultLabel : selectedOption.content}
        </div>
      </div>
      {showSelector && (
        <div
          className={
            "absolute top-12 z-50 max-h-80 w-full overflow-y-auto border-2 border-green rounded-md p-2 shadow-lg bg-white"
          }
        >
          {searchable && (
            <div className={"relative"}>
              <input
                type="text"
                onChange={handleFilter}
                value={filter}
                className={"mb-2 w-full rounded p-1 px-3 text-xs focus:outline-none border"}
              />
              <i
                className={
                  "fa-solid fa-magnifying-glass pointer-events-none absolute right-2 top-2 cursor-text text-xs leading-[14px]"
                }
              ></i>
            </div>
          )}
          {options
            .filter((option) => (filter === "" ? true : option.searchValue.toLowerCase().includes(filter.toLowerCase())))
            .map((option, index) => (
              <label
                className={option.className + " border-3 flex cursor-pointer select-none truncate text-left"}
                key={index}
                onClick={() => onOptionClick(option.value)}
              >
                {option.content}
              </label>
            ))}
        </div>
      )}
    </div>
  );
};

export { Dropdown };
