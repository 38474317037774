import React, { useState } from "react";

const ScrollToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const scrollFunction = () => {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-2 right-2 z-top cursor-pointer shadow-md">
      <button onClick={scrollTop} className="bg-beige text-green py-2 px-3 border-2 border-green rounded-md">
        <i className="fa-solid fa-chevron-up"></i>
      </button>
    </div>
  );
};

export default ScrollToTopButton;
