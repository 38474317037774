import { Link } from "react-router-dom";

type Props = {
  onClick: () => void;
};

const Footer: React.FC<Props> = ({ onClick }) => {
  return (
    <div className="absolute bottom-0 w-full">
      <div className="bg-beige w-full py-8">
        <p className="text-center text-[10px] mt-1 text-green font-semibold px-4">
          © 2024 Le Serpentaire - Tous droits réservés | Conçu avec{" "}
          <i className="fa-solid fa-heart text-[8px] text-red-500" onClick={onClick}></i> par{" "}
          <a href="https://www.linkedin.com/in/sebastien-kerroue/" target="_blank" rel="noreferrer">
            Sébastien
          </a>
        </p>
      </div>
      <div className="bg-green w-full text-center pb-1">
        <Link to="/legal-mentions" className="text-beige text-[10px] font-semibold">
          Mentions légales
        </Link>
      </div>
    </div>
  );
};

export default Footer;
