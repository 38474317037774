import { Link } from "react-router-dom";
import AboutCard from "../../components/Card/AboutCard";
//import SocialCard from "../../components/Card/SocialCard";

const About: React.FC = () => {
  return (
    <div className="items-center justify-center ">
      <div className="w-full bg-beige py-7">
        <div className="w-11/12 mx-auto">
          <h1 className="text-3xl text-green text-center font-garamond font-extrabold">
            Bonjour à tous, soyez les bienvenus sur Le Serpentaire !
            <br />
            <span className="text-6xl">🐍</span>
          </h1>
          <div className="w-11/12 md:w-2/3 mt-4 max-w-4xl mx-auto">
            <p className="text-sm text-green font-medium">
              Mais de quoi parle-t-on ici ? Et bien de tout ! <br />
              <br />
              L’idée de ce blog, c’est de partager des choses qui nous intéressent, qui nous passionnent, en combinant purement et
              simplement nos centres d’intérêt en un seul et même endroit !<br />
              <br />
              Juste trois potes qui sur un coup de tête ont décidés de créer un blog pour donner leur avis non sollicités sur des
              sujets divers et variés.
            </p>
          </div>
        </div>
      </div>
      <div className="w-11/12 mt-10 max-w-4xl mx-auto">
        <h1 className="text-3xl font-extrabold font-garamond text-green text-center">Qui sommes-nous ?</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-8">
          <AboutCard
            name={"Lisou"}
            image={"/img/lisa.jpg"}
            description={
              "Moi c’est Lisou, l’électron libre de la team ! J’aime apprendre de nouvelles choses et partager mes découvertes autour de moi. On retrouvera ma patte dans des articles aussi bien sur la cosmétique que sur les jeux de sociétés ou encore sur des débats en tous genres. Point faible : les chatons."
            }
          />
          <AboutCard
            name={"Clou"}
            image={"/img/chloe.jpg"}
            description={
              "Passionnée de rien mais intéressée par tout, j’adore découvrir et apprendre pleins de trucs randoms. La diversité dans la lecture et l’audiovisuel me permet facilement de le faire, c’est pour ça que j’adore m’aventurer sur pleins de médiums différents, même si mon domaine de prédilection reste les mangas et la japanimation ;)"
            }
          />
          <AboutCard
            name={"Ratus"}
            image={"/img/leane.jpg"}
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tempus ex ante, eget auctor libero ultricies nec. Nam scelerisque consectetur scelerisque. Maecenas laoreet tortor quis pulvinar volutpat."
            }
          />
        </div>
        <h1 className="text-3xl font-extrabold font-garamond text-green text-center mt-20">Approche</h1>
        <div className="mt-8">
          <p className="text-sm text-green font-medium mb-8">
            Notre objectif est de nous éclater à écrire sur des sujets intéressants, partager nos avis dessus et échanger au
            maximum avec vous.
          </p>
          <p className="text-sm text-green font-medium">
            Ce blog est notre caverne d’Ali Baba ou bien notre magasin de curiosités, c’est toi qui choisi. Ici pas de règles,
            tout ce qui nous intrigue y passera. Certaines semaines seront plus animées que d’autres : Stay Tunned !<br />
            <br />
            N’hésites pas à nous faire des retours, notre but est avant tout de faire de ce blog un espace convivial et ouvert à
            tous.tes.
          </p>
        </div>
      </div>
      <div className="w-full bg-beige mt-10 pb-7">
        <h1 className="text-3xl font-extrabold font-garamond text-green text-center mt-20 py-7">Thèmes abordés</h1>
        <div className="w-11/12 mt-8 max-w-4xl mx-auto">
          <p className="text-sm text-green font-medium mb-8">Nous parlerons essentiellement de :</p>
          <ul className="text-sm text-green font-medium mb-8 list-disc ml-8">
            <li>Séries (classiques, animes, kdramas)</li>
            <li>Films</li>
            <li>Jeux de société (cartes ou plateau) </li>
            <li>Lecture (romans en tout genre, littérature féministe, mangas, romans graphiques, etc.)</li>
            <li>Beauté (self care, maquillage)</li>
            <li>Développement personnel</li>
            <li>Sujets de société</li>
          </ul>
        </div>
        {/*<h1 className="text-3xl font-extrabold font-garamond text-green text-center mt-20 py-7">Nous retrouver ailleurs</h1>
                <p className="text-center text-sm text-green font-medium">Vous pouvez également nous retrouver ici :</p>
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-8 mt-8 md:w-1/3 mx-auto px-4">
                    <SocialCard name={"Instagram"} url={"#"} icon={"fa-instagram"} />
                    <SocialCard name={"Youtube"} url={"#"} icon={"fa-youtube"} />
                    <SocialCard name={"LinkedIn"} url={"#"} icon={"fa-linkedin"} />
    </div>*/}
      </div>
      <h1 className="text-3xl font-extrabold font-garamond text-green text-center mt-10 py-7">Conclusion</h1>
      <div className="md:w-2/3 mx-auto mt-8 max-w-4xl w-11/12">
        <p className="text-sm text-green font-medium mb-8">
          Nous voilà arrivés au terme de la présentation du blog ! On espère que vous apprécierez les sujets abordés autant que
          nous, n'hésitez pas également à nous proposer des sujets qui vous intéressent :)
          <br />
          <br />
          Merci beaucoup à Seb pour son travail et son investissement sur la conception du blog, c’est grâce à lui que le site
          existe et qu’il est aussi qualitatif !!
        </p>
      </div>
      <div className="mx-auto w-fit bg-green transition-all duration-150 hover:md:opacity-80 cursor-pointer rounded-md">
        <Link to={"/all-articles"}>
          <p className="text-light-beige font-medium px-8 py-2 ">Consulter les articles</p>
        </Link>
      </div>
    </div>
  );
};

export default About;
