import { Link } from "react-router-dom";

const BigCard: React.FC = () => {
  return (
    <>
      <div
        className=" md:min-h-[240px] bg-beige overflow-hidden relative bg-cover bg-center pb-10 md:pb-0 rounded-xl"
        style={{ backgroundImage: 'url("/img/le-serpentaire-home.png")' }}
      >
        <h1 className="bottom-2 left-2 text-4xl font-garamond font-bold text-green pt-4 px-6">
          Bienvenue sur le blog
          <br />
          Le Serpentaire 🐍 !
        </h1>
        <Link
          to={"/all-articles"}
          className="bg-green text-beige px-4 py-2 relative left-5 top-4 cursor-pointer mx-1 whitespace-nowrap block w-fit md:inline-block mb-2 md:mb-0 rounded-md transition-all duration-150 hover:md:opacity-80"
        >
          Consulter les articles
        </Link>
        <Link
          to={"/about"}
          className="border-2 border-green text-green px-4 py-1.5 relative left-5 top-4 cursor-pointer mx-1 whitespace-nowrap block w-fit md:inline-block rounded-md transition-all duration-150 hover:md:opacity-80"
        >
          A propos
        </Link>
      </div>
    </>
  );
};

export default BigCard;
