const LoadingListing: React.FC = () => {

    return (
        <div className="w-screen flex items-center justify-center pt-10">
            <div className="w-11/12">
                <div className="max-w-screen-md mx-auto">
                    <div className="mt-4 bg-beige w-full h-24 animate-pulse"></div>
                    <div className="mt-4 bg-beige w-full h-24 animate-pulse"></div>
                    <div className="mt-4 bg-beige w-full h-24 animate-pulse"></div>
                    <div className="mt-4 bg-beige w-full h-24 animate-pulse"></div>
                    <div className="mt-4 bg-beige w-full h-24 animate-pulse"></div>
                    <div className="mt-4 bg-beige w-full h-24 animate-pulse"></div>
                </div>
            </div>
        </div>
    );
}

export default LoadingListing;