const LengalMentions = () => {
  return (
    <div className="w-11/12 mx-auto text-green mt-4">
      <h1 className="font-garamond font-bold text-4xl">Mentions Légales</h1>

      <h2 className="font-garamond font-bold text-2xl my-3">Informations Générales</h2>
      <p>
        <strong>Nom du site web</strong> : Le Serpentaire
      </p>
      <p>
        <strong>URL du site web</strong> :{" "}
        <a className="underline" href="https://le-serpentaire.sebastien-os.fr/">
          https://le-serpentaire.sebastien-os.fr/
        </a>
      </p>
      <p>
        <strong>Contact</strong> :{" "}
        <a className="underline" href="mailto:contact@le-serpentaire.sebastien-os.fr">
          contact@le-serpentaire.sebastien-os.fr
        </a>
      </p>

      <h2 className="font-garamond font-bold text-2xl my-3">Hébergement</h2>
      <p>
        <strong>Hébergeur</strong> :
      </p>
      <p>
        OVH SAS
        <br />
        2 rue Kellermann
        <br />
        59100 Roubaix
        <br />
        France
        <br />
        Téléphone : +33 9 72 10 10 07
      </p>

      <h2 className="font-garamond font-bold text-2xl my-3">Protection des Données Personnelles</h2>
      <p>
        Conformément aux dispositions de la loi n°78-17 du 6 janvier 1978 modifiée relative à l'informatique, aux fichiers et aux
        libertés, vous disposez d'un droit d'accès, de modification, de rectification et de suppression des données qui vous
        concernent. Vous pouvez exercer ce droit en nous contactant par email à{" "}
        <a className="underline" href="mailto:contact@le-serpentaire.sebastien-os.fr">
          contact@le-serpentaire.sebastien-os.fr
        </a>
        .
      </p>

      <h2 className="font-garamond font-bold text-2xl my-3">Utilisation des Cookies</h2>
      <p>
        Le site{" "}
        <a className="underline" href="https://le-serpentaire.sebastien-os.fr/">
          Le Serpentaire
        </a>{" "}
        peut-être amené à vous demander l'acceptation des cookies pour des besoins de statistiques et d'affichage. Un cookie est
        une information déposée sur votre disque dur par le serveur du site que vous visitez. Il contient plusieurs données qui
        sont stockées sur votre ordinateur dans un simple fichier texte auquel un serveur accède pour lire et enregistrer des
        informations.
      </p>

      <h2 className="font-garamond font-bold text-2xl my-3">Google reCAPTCHA</h2>
      <p>
        Ce site est protégé par reCAPTCHA et les règles de confidentialité et les conditions d'utilisation de Google s'appliquent.
        <br />
        <a className="underline" href="https://policies.google.com/privacy">
          Privacy Policy
        </a>
        <br />
        <a className="underline" href="https://policies.google.com/terms">
          Terms of Service
        </a>
      </p>

      <h2 className="font-garamond font-bold text-2xl my-3">Propriété Intellectuelle</h2>
      <p>
        Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, des différents
        éléments du site est strictement interdite sans l'accord exprès par écrit de{" "}
        <a className="underline" href="mailto:contact@le-serpentaire.sebastien-os.fr">
          contact@le-serpentaire.sebastien-os.fr
        </a>
        .
      </p>

      <h2 className="font-garamond font-bold text-2xl my-3">Limitation de Responsabilité</h2>
      <p>
        Les informations contenues sur ce site sont aussi précises que possible et le site est périodiquement remis à jour, mais
        peut néanmoins contenir des inexactitudes, des omissions ou des lacunes. Si vous constatez une lacune, erreur ou ce qui
        semble être un dysfonctionnement, merci de bien vouloir le signaler par email à{" "}
        <a className="underline" href="mailto:contact@le-serpentaire.sebastien-os.fr">
          contact@le-serpentaire.sebastien-os.fr
        </a>
        , en décrivant le problème de la manière la plus précise possible (page posant problème, type d’ordinateur et de
        navigateur utilisé, etc.).
      </p>

      <h2 className="font-garamond font-bold text-2xl my-3">Liens Hypertextes</h2>
      <p>
        Le site peut contenir des liens hypertextes vers d’autres sites web. Cependant,{" "}
        <a className="underline" href="https://le-serpentaire.sebastien-os.fr/">
          Le Serpentaire
        </a>{" "}
        n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité
        de ce fait.
      </p>

      <h2 className="font-garamond font-bold text-2xl my-3">Droit Applicable</h2>
      <p>
        Tout litige en relation avec l’utilisation du site{" "}
        <a className="underline" href="https://le-serpentaire.sebastien-os.fr/">
          Le Serpentaire
        </a>{" "}
        est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Paris.
      </p>
    </div>
  );
};

export default LengalMentions;
