const LoadingArticle: React.FC = () => {
    return (
        <div className="mt-10 pb-20" id="article-page">
            <div className="container w-11/12 md:w-1/2 mx-auto relative">
                <div className="max-w-[640px] border-4 border-beige bg-beige h-40 relative bg-cover mx-auto animate-pulse"></div>
                <div className="max-w-[640px] mx-auto">
                    <div className="mt-5 bg-beige w-52 h-8 animate-pulse"></div>
                    <div className="mt-5 bg-beige w-80 h-8 animate-pulse"></div>
                </div>
                <div className="mt-20 bg-beige w-full h-4 animate-pulse"></div>
                <div className="mt-4 bg-beige w-full h-4 animate-pulse"></div>
                <div className="mt-4 bg-beige w-full h-4 animate-pulse"></div>
                <div className="mt-4 bg-beige w-full h-4 animate-pulse"></div>
                <div className="mt-4 bg-beige w-full h-28 animate-pulse"></div>
                <div className="mt-4 bg-beige w-full h-4 animate-pulse"></div>
                <div className="mt-4 bg-beige w-full h-4 animate-pulse"></div>
            </div>
        </div>
    );
}

export default LoadingArticle;