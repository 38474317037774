// CommentForm.tsx
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import CommentForm from "./CommentForm";

type Props = {
  articleId: number;
  comments: ArticleComment[];
};

const Comment: React.FC<Props> = ({ articleId, comments }) => {
  return (
    <div>
      <GoogleReCaptchaProvider reCaptchaKey="6LesTAoqAAAAACGDsJLOH7ej6Pq3n_zmKaOO8TIH">
        <CommentForm articleId={articleId} comments={comments} />
      </GoogleReCaptchaProvider>
    </div>
  );
};

export default Comment;
