import PaginationButton from "../../../components/Pagination/PaginationButton";

export const generatePagination = (dataLength:number, onClick:any, currentPage:number) => {
    const numberOfPage = Math.ceil(dataLength / 10);
    let result:any = [];
    if(dataLength === 0) return result;
    for (let i = 1; i <= numberOfPage; i++){
        result.push(<PaginationButton key={i} number={i} onClick={onClick} currentPage={(currentPage === i)} />)
    }
    return result;
}