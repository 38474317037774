import { Link } from "react-router-dom";

type Props = {
  name: string;
  url: string;
};

const CategoryLink: React.FC<Props> = ({ name, url }) => {
  return (
    <Link to={url} className="cursor-pointer transition-all duration-150 hover:md:opacity-80">
      <div className="w-full p-2 flex">
        <div className="rounded-full bg-green w-10 h-10">
          <p className="text-center text-beige font-garamond text-2xl font-extrabold relative top-1">{name[0]}</p>
        </div>
        <p className="text-green font-semibold text-center text-lg mb-2 truncate pl-2 relative top-1.5">{name}</p>
      </div>
    </Link>
  );
};

export default CategoryLink;
