import { useState } from "react";
import { generateSummary } from "./utils/generateSummary";

type Props = {
  data: any;
};

const Summary: React.FC<Props> = ({ data }) => {
  const [openSummary, setOpenSummary] = useState<boolean>(false);
  const summaryData = generateSummary(data);

  if (summaryData.length === 0) return <div className="mb-10"></div>;

  return (
    <div className="max-w-[640px] mx-auto transition-all duration-200 mb-10">
      <div
        className={
          (openSummary ? "rounded-t-md" : "rounded-md") + " w-full bg-green text-light-beige py-2 relative cursor-pointer"
        }
        onClick={() => setOpenSummary(!openSummary)}
      >
        <p className="text-center font-garamond text-2xl font-bold">Sommaire</p>
        <i className={(openSummary ? "fa-chevron-up" : "fa-chevron-down") + " fa-solid absolute top-4 right-4"}></i>
      </div>
      {openSummary && (
        <div className="max-w-[640px] mx-auto border-b-2 border-l-2 border-r-2 border-green rounded-b-md">{summaryData}</div>
      )}
    </div>
  );
};

export default Summary;
