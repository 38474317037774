import { Link } from "react-router-dom";
import { generateSlug } from "./utils/generateSlug";
import ReviewBadge from "../Badge/ReviewBadge";

type Props = {
  title: string;
  thumbnail: string;
  author: string;
  date: string;
  review: Review | null;
};

const Card: React.FC<Props> = ({ title, thumbnail, author, date, review }) => {
  return (
    <Link
      to={generateSlug(title)}
      className="break-inside-avoid-column cursor-pointer transition-all duration-150 hover:md:opacity-80"
      title={title}
    >
      <div
        className="border-2 border-green h-60 bg-beige bg-cover bg-center rounded-md relative"
        style={{ backgroundImage: 'url("' + thumbnail + '")' }}
      >
        {review && <ReviewBadge mark={review.mark} />}
      </div>
      <div className="">
        <h1 className={"font-garamond text-green font-bold text-xl px-2 pt-2 text-ellipsis overflow-hidden whitespace-nowrap"}>
          {title}
        </h1>
        <p className="text-xs font-semibold text-green px-2 pb-2">
          Rédigé par {author} le {date}
        </p>
      </div>
    </Link>
  );
};

export default Card;
