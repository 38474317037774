const P404: React.FC = () => {
    return (
        <div className="w-screen flex items-center justify-center pt-10">
            <div className="w-11/12">
                <h1 className="text-6xl font-bold text-green text-center">404 ! <br /><span className="!text-2xl">La page demandée n'existe pas...</span></h1>
            </div>
        </div>
    )
}

export default P404;