import React from "react";

type Props = {
  mark: number;
};

const ReviewBadge: React.FC<Props> = ({ mark }) => {
  return (
    <div className="absolute bottom-2 right-2 bg-green text-light-beige px-2 py-1 rounded-md h-12 w-12 text-center shadow-sm">
      <p className="font-semibold absolute">
        {mark < 10 && "0"}
        {mark}
      </p>
      <div className="w-6 h-[1px] bg-beige -rotate-[20deg] absolute top-7 left-3"></div>
      <p className="font-semibold absolute !text-beige text-xxs bottom-1 right-2">20</p>
    </div>
  );
};

export default ReviewBadge;
