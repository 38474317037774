import { Outlet, useLocation } from "react-router-dom";
import NavBar from "./components/Navbar/NavBar";
import { createContext, useEffect, useState } from "react";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop.tsx/ScrollToTop";
import { getArticleData } from "./pages/Article/utils/getArticleData";
import ScrollToTopButton from "./components/ScrollToTop.tsx/ScrollToTopButton";

export const ArticlesContext = createContext<any>(null);

type Data = {
  articles?: Article[];
  categories?: Category[];
};

function App() {
  const [data, setData] = useState<Data | null>(null);
  const [responseArticles, setResponseArticle] = useState<Article[]>([]);
  const [responseCategories, setResponseCategories] = useState<Category[]>([]);
  const [specialTheme, setSpecialTheme] = useState<boolean>(false);
  const location = useLocation();

  // Getting articles
  useEffect(() => {
    fetch("https://dashboard.sebastien-os.fr/api/articles/all")
      .then((response) => response.json())
      .then((data) => data.reverse())
      .then((json) => setResponseArticle(json))
      .catch((error) => console.error(error));
  }, []);

  // Getting categories
  useEffect(() => {
    fetch("https://dashboard.sebastien-os.fr/api/categories/all")
      .then((response) => response.json())
      .then((json) => setResponseCategories(json))
      .catch((error) => console.error(error));
  }, []);

  // Merging data
  useEffect(() => {
    setData({ articles: responseArticles, categories: responseCategories });
  }, [responseArticles, responseCategories]);

  // Set tab title
  useEffect(() => {
    // Case for special pages
    if (["/", "/about", "/all-articles", "/404", "/legal-mentions"].includes(window.location.pathname))
      document.title = "Le Serpentaire";
    else if (data?.articles && data.articles.length > 0) {
      const article = getArticleData(data.articles, window.location.pathname.split("/").pop() as string);
      document.title = article.title + " | Le Serpentaire";
    }
  }, [location, data]);

  return (
    <ArticlesContext.Provider value={data}>
      <ScrollToTop />
      <ScrollToTopButton />
      <div className="bg-light-beige min-h-screen relative pb-60" id={specialTheme ? "special-theme" : ""}>
        <NavBar />
        <Outlet />
        <Footer onClick={() => setSpecialTheme(!specialTheme)} />
      </div>
    </ArticlesContext.Provider>
  );
}

export default App;
