type Props = {
  name: string;
  image: string;
  description: string;
};

const AboutCard: React.FC<Props> = ({ name, image, description }) => {
  return (
    <div className="max-w-[270px] mx-auto">
      <div
        className=" w-full h-[220px] bg-cover bg-center rounded-t-md"
        style={{ backgroundImage: 'url("' + image + '")' }}
      ></div>
      <div className=" p-4 bg-beige rounded-b-md">
        <p className="text-center font-garamond text-2xl font-extrabold text-green mb-2">{name}</p>
        <p className="text-sm font-medium text-green">{description}</p>
      </div>
    </div>
  );
};

export default AboutCard;
