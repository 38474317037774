export const generateSlug = (title: string) => {
  return title
    .replaceAll(" ", "-")
    .replaceAll("/", "")
    .replaceAll('"', "")
    .replaceAll(":", "")
    .replaceAll("?", "")
    .replaceAll("!", "")
    .replaceAll(".", "")
    .replaceAll("=", "")
    .replaceAll("+", "")
    .replaceAll("(", "")
    .replaceAll(")", "")
    .replaceAll(/-+/g, "-")
    .replaceAll(/-+$/g, "")
    .toLowerCase()
    .normalize("NFD")
    .replaceAll(/[\u0300-\u036f]/g, "");
};
