import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Article from "./pages/Article/Article";
import P404 from "./pages/P404/P404";
import Listing from "./pages/Listing/Listing";
import LengalMentions from "./pages/LegalMentions/LegalMentions";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Navigate to="/404" />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/all-articles",
        element: <Listing />,
      },
      {
        path: "/404",
        element: <P404 />,
      },
      {
        path: "/legal-mentions",
        element: <LengalMentions />,
      },
      {
        path: "/:articleId",
        element: <Article />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(<RouterProvider router={router} />);
