type Props = {
  style: "ordered" | "unordered";
  items: string[];
};

const List: React.FC<Props> = ({ style, items }) => {
  if (style === "ordered") {
    return (
      <ol className="list-decimal pl-8 my-2">
        {items.map((e: any, index: number) => (
          <li key={index}>{e.replace("&nbsp;", "").replaceAll("<br>", "")}</li>
        ))}
      </ol>
    );
  }
  return (
    <ul className="list-disc pl-8 my-2">
      {items.map((e: any, index: number) => (
        <li key={index}>{e.replace("&nbsp;", "").replaceAll("<br>", "")}</li>
      ))}
    </ul>
  );
};

export default List;
