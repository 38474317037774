import { Link, useLocation } from "react-router-dom";
import NavItem from "./NavItem";
import { useContext, useEffect, useRef, useState } from "react";
import { ArticlesContext } from "../../App";
import { generateSubCategoriesList } from "../utils/generateSubCategoriesList";
import ArticleCardForMenu from "../Card/ArticleCardForMenu";
import { generateSlug } from "../Card/utils/generateSlug";

type SubMenuData = {
  parentId: number;
  childs: Category[];
};

const NavBar: React.FC = () => {
  const location = useLocation().pathname;
  const data = useContext(ArticlesContext);
  const componentRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [subMenuData, setSubMenuData] = useState<SubMenuData | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    setSubMenuData({ parentId: categories[0]?.id || -1, childs: categories[0]?.childs || [] });
  }, [categories]);

  useEffect(() => {
    if (!data?.categories) return;
    setCategories(generateSubCategoriesList(data.categories));
  }, [data?.categories]);

  if (!data?.categories) return <></>;
  const articles = data.articles;

  const hideSubMenuAndResetData = () => {
    setIsMenuOpen(false);
    setSubMenuData({ parentId: categories[0]?.id || -1, childs: categories[0]?.childs || [] });
  };

  const updateCategoryInformation = (parentId: number, childs: Category[]) => {
    setSubMenuData({ parentId: parentId, childs: childs });
  };

  return (
    <>
      <div className="bg-beige w-full h-16" onMouseEnter={() => hideSubMenuAndResetData()}>
        <Link to={"/"} className="font-garamond text-4xl text-green pl-4 font-bold relative top-2">
          🐍 Le Serpentaire
        </Link>
      </div>
      <div className="bg-green w-full py-3 px-8 min-h-[53px]">
        <div className="block sm:inline-block" onMouseEnter={() => hideSubMenuAndResetData()}>
          <NavItem name="Accueil" link={"/"} active={location === "/"} />
        </div>
        <Link
          to={"/all-articles"}
          className="w-fit block sm:inline-block mr-8 my-2 md:my-0 transition-all duration-150 hover:md:opacity-80"
          onMouseEnter={() => setIsMenuOpen(true)}
          onClick={() => hideSubMenuAndResetData()}
        >
          <div className="w-fit">
            <p className={"uppercase text-sm text-beige font-garamond tracking-[0.2em]"}>Les articles</p>
            {location === "/all-articles" && <div className={"bg-beige h-0.5 mt-1"}></div>}
          </div>
        </Link>
        {isMenuOpen && (
          <div
            ref={componentRef}
            onMouseLeave={() => hideSubMenuAndResetData()}
            className="absolute top-[117px] w-screen z-top left-0 hidden md:block bg-beige border-b-2 border-green pt-2 px-4 pb-4 shadow-lg"
          >
            <div className="container w-11/12 mx-auto">
              <div className="flex">
                <div className="w-fit">
                  <p className={"text-3xl font-garamond text-green font-bold mb-4"}>Nos catégories</p>
                  {categories.map((category: Category, index: number) => {
                    return (
                      <div key={index}>
                        <Link
                          reloadDocument
                          to={"/all-articles?category=" + generateSlug(category.name)}
                          className="transition-all duration-150 hover:md:opacity-80"
                          onMouseEnter={
                            category.childs
                              ? () => updateCategoryInformation(category.id, category.childs || [])
                              : () => updateCategoryInformation(category.id, [])
                          }
                        >
                          <div className="bg-beige pl-2 pr-4 py-1 text-green flex">
                            <div className="rounded-full bg-green w-6 h-6 relative top-1">
                              <p className="text-center text-beige font-garamond font-extrabold">{category.name[0]}</p>
                            </div>
                            <p className="text-green font-semibold text-center mb-2 truncate pl-2 relative top-1">
                              {category.name}
                            </p>
                            {category.childs && (
                              <div className="ml-auto w-fit px-1 relative left-3 top-1">
                                <i className="fas fa-chevron-right text-base text-green"></i>
                              </div>
                            )}
                          </div>
                        </Link>
                        {index !== categories.length - 1 && <div className="w-full h-0.5 bg-green opacity-20"></div>}
                      </div>
                    );
                  })}
                </div>
                {subMenuData?.childs && subMenuData.childs.length > 0 && (
                  <div className="block ml-2">
                    <p className="text-xl font-garamond text-green font-bold w-fit break-keep mb-6 relative top-2.5">
                      Sous-catégories
                    </p>
                    {subMenuData.childs.map((category: Category, index: number) => {
                      return (
                        <div key={index}>
                          <Link
                            key={index}
                            reloadDocument
                            to={"/all-articles?category=" + generateSlug(category.name)}
                            className="transition-all duration-150 hover:md:opacity-80"
                          >
                            <div className="border-beige border-4 pl-2 pr-4  text-green flex bg-beige">
                              <p className="font-semibold text-center mb-2 truncate pl-2 relative top-1">{category.name}</p>
                            </div>
                          </Link>
                          {index !== subMenuData.childs.length - 1 && <div className="w-full h-0.5 bg-green opacity-20"></div>}
                        </div>
                      );
                    })}
                  </div>
                )}
                {articles && (
                  <div className="ml-8 overflow-hidden">
                    <p className="text-xl font-garamond text-green font-bold w-fit break-keep mb-6 relative top-2.5">
                      Derniers articles
                    </p>
                    <div className="h-full overflow-x-auto">
                      <div className="flex w-fit">
                        {articles
                          .filter(
                            (article: Article) =>
                              article.categories.includes(subMenuData?.parentId || -1) ||
                              // Check if some childs of the subcategory are in the article's categories
                              (subMenuData?.childs &&
                                subMenuData.childs.some((child: Category) => article.categories.includes(child.id)))
                          )
                          .slice(0, 5)
                          .map((article: Article) => {
                            return (
                              <ArticleCardForMenu
                                key={article.id}
                                title={article.title}
                                thumbnail={article.thumbnail}
                                onClick={() => hideSubMenuAndResetData()}
                                review={article.review}
                              />
                            );
                          })}
                        {articles.filter(
                          (article: Article) =>
                            article.categories.includes(subMenuData?.parentId || -1) ||
                            // Check if some childs of the subcategory are in the article's categories
                            (subMenuData?.childs &&
                              subMenuData.childs.some((child: Category) => article.categories.includes(child.id)))
                        ).length === 0 && <p className="text-green">Aucun article pour le moment 🐍</p>}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="block sm:inline-block" onMouseEnter={() => hideSubMenuAndResetData()}>
          <NavItem name="À propos" link={"/about"} active={location === "/about"} />
        </div>
      </div>
    </>
  );
};

export default NavBar;
