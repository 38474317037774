// CommentForm.tsx
import React, { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { getDate } from "./utils/getDate";

type Props = {
  articleId: number;
  comments: ArticleComment[];
};

const CommentForm: React.FC<Props> = ({ articleId, comments }) => {
  const [comment, setComment] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [state, setState] = useState<"idle" | "success" | "error">("idle");
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      alert("Recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("submit_comment");

    const response = await fetch("https://dashboard.sebastien-os.fr/api/comment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ comment, token, article_id: articleId, name }),
    });

    const result = await response.json();
    if (result.success) {
      // Add the new comment at the beginning of the list
      comments.unshift({
        id: Math.random() * 1000,
        name,
        content: comment,
        created_at: new Date().toISOString(),
      });
      setComment("");
      setName("");
      setState("success");
      // Reset state after 3 second
      setTimeout(() => {
        setState("idle");
      }, 3000);
    } else {
      setState("error");
      // Reset state after 3 second
      setTimeout(() => {
        setState("idle");
      }, 3000);
    }
  };

  return (
    <div className="container w-11/12 lg:w-1/2 mx-auto relative mt-20 max-w-4xl">
      <div className="bg-green py-2 mb-4 rounded-md">
        <p className="font-garamond text-light-beige text-center text-2xl font-bold">Commentaires</p>
      </div>
      <div className="mb-4">
        {comments.map((comment) => (
          <div key={comment.id} className="bg-beige p-4 mb-4 rounded-md">
            <p className="text-green font-bold">
              {comment.name}
              <span className="text-xs font-normal ml-2 float-right">{getDate(comment.created_at)}</span>
            </p>
            <p className="text-green whitespace-pre-line">{comment.content.replace(/\n{2,}/g, "\n\n")}</p>
          </div>
        ))}
      </div>
      {comments.length === 0 && <p className="text-center text-green mb-4">Aucun commentaire pour le moment</p>}
      <div>
        <div className="bg-green py-2 rounded-t-md">
          <p className="font-garamond text-light-beige text-center text-2xl font-bold">Ajouter un commentaire</p>
        </div>
        {state === "idle" && (
          <form onSubmit={handleSubmit} className="border-2 border-green p-4 rounded-b-md">
            <input
              defaultValue={name}
              type="text"
              placeholder="Votre nom"
              className="w-full p-2 bg-beige placeholder:text-green text-green placeholder:font-semibold placeholder:opacity-60 h-11 rounded-md mb-4"
              onChange={(e) => setName(e.target.value)}
              max={30}
              required
            />
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              required
              placeholder="Votre commentaire"
              className="w-full p-2 bg-beige placeholder:text-green text-green placeholder:font-semibold placeholder:opacity-60 h-40 rounded-md mb-4 resize-none"
              maxLength={1000}
            />
            <p className="absolute right-5 text-xs text-green">{comment.length} / 1000</p>
            <button
              type="submit"
              className="text-center bg-green text-light-beige font-medium w-fit mx-auto px-4 py-2 cursor-pointer transition-all duration-150 hover:md:opacity-80 rounded-md"
            >
              Ajouter un commentaire
            </button>
            <button type="submit"></button>
          </form>
        )}
        {state === "success" && <p className="text-center text-green mt-4">Commentaire ajouté avec succès</p>}
        {state === "error" && <p className="text-center text-red mt-4">Erreur lors de l'ajout du commentaire</p>}
      </div>
    </div>
  );
};

export default CommentForm;
