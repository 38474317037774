import { generateSlug } from "../../../components/Card/utils/generateSlug";

export const generateSummary = (data: any) => {
  const result: any = [];
  data.forEach((e: any, index: number) => {
    if (e.type === "header") {
      switch (e.data.level) {
        case 1:
        case "1":
          result.push(
            <a
              href={"#" + generateSlug(e.data.text)}
              className="text-green text-xl font-garamond font-bold block !no-underline px-2 py-0.5"
              key={index}
            >
              {e.data.text.replace("&nbsp;", " ")}
            </a>
          );
          break;
        case 2:
        case "2":
          result.push(
            <a
              href={"#" + generateSlug(e.data.text)}
              className="text-green text-xl font-garamond font-bold block !no-underline px-2 py-0.5"
              key={index}
            >
              {e.data.text.replace("&nbsp;", " ")}
            </a>
          );
          break;
        case 3:
        case "3":
          result.push(
            <a
              href={"#" + generateSlug(e.data.text)}
              className="text-green text-xl font-garamond font-bold block !no-underline px-2 py-0.5"
              key={index}
            >
              {e.data.text.replace("&nbsp;", " ")}
            </a>
          );
          break;
        case 4:
        case "4":
          result.push(
            <a
              href={"#" + generateSlug(e.data.text)}
              className="text-green text-xl font-garamond font-bold block !no-underline px-2 py-0.5"
              key={index}
            >
              {e.data.text.replace("&nbsp;", " ")}
            </a>
          );
          break;
        case 5:
        case "5":
          result.push(
            <a
              href={"#" + generateSlug(e.data.text)}
              className="text-green text-xl font-garamond font-bold block !no-underline px-2 py-0.5"
              key={index}
            >
              {e.data.text.replace("&nbsp;", " ")}
            </a>
          );
          break;
        case 6:
        case "6":
          result.push(
            <a
              href={"#" + generateSlug(e.data.text)}
              className="text-green text-xl font-garamond font-bold block !no-underline px-2 py-0.5"
              key={index}
            >
              {e.data.text.replace("&nbsp;", " ")}
            </a>
          );
          break;
      }
    }
  });
  return result;
};
