import { Link } from "react-router-dom";
import { generateSlug } from "./utils/generateSlug";
import ReviewBadge from "../Badge/ReviewBadge";

type Props = {
  title: string;
  thumbnail: string;
  author: string;
  date: string;
  categories: string[];
  review: Review | null;
};

const ListingLongCard: React.FC<Props> = ({ title, thumbnail, author, date, categories, review }) => {
  return (
    <Link
      to={"/" + generateSlug(title)}
      className="md:flex cursor-pointer mb-4 transition-all duration-150 hover:md:opacity-80"
      title={title}
    >
      <div
        className="border-2 border-green h-24 w-full md:w-60 bg-beige bg-cover bg-center mt-4 md:mt-0 relative rounded-md"
        style={{ backgroundImage: 'url("' + thumbnail + '")' }}
      >
        {review && <ReviewBadge mark={review.mark} />}
      </div>
      <div className="w-full md:overflow-hidden">
        <p
          className={
            "font-garamond text-green font-bold text-xl px-2 pt-2 md:text-ellipsis md:overflow-hidden md:whitespace-nowrap"
          }
        >
          {title}
        </p>
        <p className="text-xs font-semibold text-green px-2 pb-2">
          Rédigé par {author} le {date}
        </p>
        <div className="flex">
          {categories.map((cat, index) => (
            <div
              key={index}
              className="ml-2 text-[10px] bg-green font-semibold px-1.5 py-0.5 w-fit mb-2 text-light-beige rounded-sm"
            >
              {cat}
            </div>
          ))}
        </div>
      </div>
    </Link>
  );
};

export default ListingLongCard;
