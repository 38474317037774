type Props = {
  url: string;
  caption: string;
  stretched: boolean;
  withBackground: boolean;
  withBorder: boolean;
};

const CustomImage: React.FC<Props> = ({ url, caption, stretched, withBackground, withBorder }) => {
  const customCaption = caption
    ?.replace("<br>", "")
    .replace("<b>", "")
    .replace("<b/>", "")
    .replace("<i>", "")
    .replace("</i>", "");

  return (
    <div className={(withBackground ? "bg-beige py-4 rounded-md" : "") + " py-4"}>
      <img
        src={url}
        alt=""
        className={(stretched ? "w-full" : "") + (withBorder ? " border-green border-2" : "") + " mx-auto my-2 rounded-md"}
      />
      {caption !== "" && <p className="text-center text-green font-bold text-xs">{customCaption}</p>}
    </div>
  );
};

export default CustomImage;
