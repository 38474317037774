const Delimiter: React.FC = () => {
    return (
        <div className="text-center my-8 text-green">
            <i className="fa-solid fa-asterisk mx-2 text-xs"></i>
            <i className="fa-solid fa-asterisk mx-2 text-xs"></i>
            <i className="fa-solid fa-asterisk mx-2 text-xs"></i>
        </div>
    )
}

export default Delimiter;