import { useContext, useEffect, useState } from "react";
import { ArticlesContext } from "../../App";
import { getArticleData } from "./utils/getArticleData";
import { Link, Navigate } from "react-router-dom";
import { generateArticleContent } from "./utils/generateArticleContent";
import { getDate } from "./utils/getDate";
import LoadingArticle from "../Loading/LoadingArticle";
import Summary from "./Summary";
import Review from "./Review";
import { getCategoriesNames } from "../../components/utils/getCategoriesNames";
import ShareButtonDesktop from "./ShareButtonDesktop";
import Comment from "./Comment";
import LoadingComments from "../Loading/LoadingComments";

const Article: React.FC = () => {
  const [isSharingModeEnabled, setIsSharingModeEnabled] = useState<boolean>(false);
  const [sharingModeState, setSharingModeState] = useState<ShareButtonState>("idle");
  const [comments, setComments] = useState<ArticleComment[] | null>(null);
  const [article, setArticle] = useState<Article>({
    id: -1,
    title: "",
    content: { blocks: [] },
    thumbnail: "",
    created_at: "",
    updated_at: "",
    visibility: false,
    highlighted: false,
    categories: [],
    authors: [],
    review: null,
  });
  // Load articles data
  const data = useContext(ArticlesContext);

  useEffect(() => {
    if (!article || article?.id === -1) return;
    fetch("https://dashboard.sebastien-os.fr/api/comments/" + article.id)
      .then((response) => response.json())
      .then((json) => setComments(json))
      .catch((error) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article?.id]);

  // Update article content
  useEffect(() => {
    if (!data?.articles || data?.articles.length === 0) return;
    const slug = window.location.pathname.split("/").pop() as string;
    setArticle(getArticleData(data.articles, slug));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.articles]);

  if (!data?.articles || !data?.categories || data?.articles.length === 0 || data?.categories.length === 0)
    return <LoadingArticle />;

  if (article === null) return <Navigate to="/404" />;

  // Check if article has content (avoid error)
  const articleHasContent = article?.content.blocks !== undefined;

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    setSharingModeState("link-copied");
    setTimeout(() => {
      setSharingModeState("idle");
      setIsSharingModeEnabled(false);
    }, 2000);
  };

  const handleShareEmail = () => {
    window.open("mailto:?subject=Regarde cet article sur Le Serpentaire&body=" + window.location.href);
    setSharingModeState("email-sent");
    setTimeout(() => {
      setSharingModeState("idle");
      setIsSharingModeEnabled(false);
    }, 2000);
  };

  const handleShareTwitter = () => {
    window.open(
      "https://twitter.com/intent/tweet?text=" + article.title + " - &url=" + window.location.href + "&hashtags=LeSerpentaire"
    );
    setSharingModeState("twitter-shared");
    setTimeout(() => {
      setSharingModeState("idle");
      setIsSharingModeEnabled(false);
    }, 2000);
  };

  const handleShareWhatsapp = () => {
    window.open("https://wa.me/?text=" + article.title + " - " + window.location.href);
    setSharingModeState("whatsapp-shared");
    setTimeout(() => {
      setSharingModeState("idle");
      setIsSharingModeEnabled(false);
    }, 2000);
  };

  const handleShareFacebook = () => {
    window.open("https://www.facebook.com/sharer/sharer.php?u=" + window.location.href);
    setSharingModeState("facebook-shared");
    setTimeout(() => {
      setSharingModeState("idle");
      setIsSharingModeEnabled(false);
    }, 2000);
  };

  return (
    <div className="mt-10 pb-20" id="article-page">
      <div className="container w-11/12 lg:w-1/2 mx-auto relative">
        <div className="relative">
          {isSharingModeEnabled && (
            <div
              className="h-full w-full bg-beige border-2 border-green z-top text-green p-4 max-w-[640px] mx-auto absolute left-1/2 rounded-md"
              style={{ transform: "translateX(-50%)" }}
            >
              <div className="absolute top-1 right-2 cursor-pointer" onClick={() => setIsSharingModeEnabled(false)}>
                <i className="fa-solid fa-xmark text-2xl"></i>
              </div>
              <p className="font-garamond text-center font-bold text-2xl mb-3 md:mb-20">Partager l'article</p>
              {sharingModeState === "idle" && (
                <>
                  <div className="w-fit mx-auto md:flex hidden">
                    <ShareButtonDesktop
                      onClick={() => copyLinkToClipboard()}
                      text="Copier le lien"
                      icon="fa-solid fa-link ml-2"
                      square={false}
                    />
                    <ShareButtonDesktop onClick={() => handleShareEmail()} text="" icon="fa-solid fa-envelope" />
                    <ShareButtonDesktop onClick={() => handleShareTwitter()} text="" icon="fa-brands fa-x-twitter" />
                    <ShareButtonDesktop onClick={() => handleShareWhatsapp()} text="" icon="fa-brands fa-whatsapp" />
                    <ShareButtonDesktop onClick={() => handleShareFacebook()} text="" icon="fa-brands fa-facebook" />
                  </div>
                  <div className="w-fit mx-auto md:hidden block">
                    <ShareButtonDesktop
                      onClick={() => copyLinkToClipboard()}
                      text="Copier le lien"
                      icon="fa-solid fa-link ml-2"
                      square={false}
                    />
                    <ShareButtonDesktop
                      onClick={() => handleShareEmail()}
                      text="Partager par e-mail"
                      icon="fa-solid fa-envelope ml-2"
                      square={false}
                    />
                    <ShareButtonDesktop
                      onClick={() => handleShareTwitter()}
                      text="Partager sur X"
                      icon="fa-brands fa-x-twitter ml-2"
                      square={false}
                    />
                    <ShareButtonDesktop
                      onClick={() => handleShareWhatsapp()}
                      text="Partager sur Whatsapp"
                      icon="fa-brands fa-whatsapp ml-2"
                      square={false}
                    />
                    <ShareButtonDesktop
                      onClick={() => handleShareFacebook()}
                      text="Partager sur Facebook"
                      icon="fa-brands fa-facebook ml-2"
                      square={false}
                    />
                  </div>
                </>
              )}
              {sharingModeState !== "idle" && (
                <div className="w-fit mx-auto">
                  <p className="text-green font-garamond text-center font-bold text-2xl">
                    {generateSharingModeText(sharingModeState)}
                  </p>
                </div>
              )}
            </div>
          )}
          <div className="max-w-[640px] mx-auto pb-2">
            <h1 className="text-3xl font-garamond font-bold text-green mb-2">{article.title}</h1>
            <div className="flex">
              {getCategoriesNames(data.categories, article.categories).map((cat: string) => (
                <p
                  key={cat}
                  className="bg-green px-1.5 py-0.5 text-light-beige mr-2 text-[10px] mb-2 w-fit font-semibold rounded-sm"
                >
                  {cat}
                </p>
              ))}
            </div>
          </div>
          <div
            className="max-w-[640px] border-2 border-green h-40 relative bg-cover bg-center mx-auto rounded-md"
            style={{ backgroundImage: 'url("' + article.thumbnail + '")' }}
          ></div>

          <div className="max-w-[640px] mb-2 mx-auto relative">
            <div
              className="absolute w-6 h-6 bg-green text-white right-0 top-3 text-center cursor-pointer md:block hidden rounded-sm transition-all duration-150 hover:md:opacity-80"
              title="Partager l'article"
              onClick={() => setIsSharingModeEnabled(true)}
            >
              <i className="fa-solid fa-share leading-5 text-sm"></i>
            </div>
            <h1 className="text-xs font-semibold text-green pt-4 pb-2">
              Article rédigé par{" "}
              {article.authors.map((e: Author, index: number) => (
                <Link key={e.id} to={"/all-articles?author=" + e.name} className="!no-underline">
                  {e.name}
                  {index !== article.authors.length - 1 && ", "}
                </Link>
              ))}{" "}
              le <b>{getDate(article.created_at)}</b>
              {article.updated_at !== article.created_at && (
                <span>
                  {" "}
                  et mit à jour le <b>{getDate(article.updated_at)}</b>
                </span>
              )}
            </h1>
          </div>
        </div>
        {!isSharingModeEnabled && (
          <div
            className="w-full bg-green text-beige max-w-[640px] mx-auto transition-all duration-200 cursor-pointer mb-4 px-2 py-2 text-center md:hidden block font-garamond font-bold text-xl"
            onClick={() => setIsSharingModeEnabled(true)}
          >
            <p>
              Partager l'article <i className="fa-solid fa-share"></i>
            </p>
          </div>
        )}
        {articleHasContent !== false && <Summary data={article.content.blocks} />}
        {articleHasContent !== false &&
          article.content.blocks.map((e: any) => (
            <div className={"overflow-x-auto overflow-y-hidden max-w-4xl mx-auto"} key={e.id}>
              {generateArticleContent(e)}
            </div>
          ))}
        {article.review !== null && (
          <Review
            title={article.review.title}
            description={article.review.description}
            mark={article.review.mark}
            positivePoints={article.review.good_points}
            negativePoints={article.review.negative_points}
          />
        )}
      </div>
      {comments === null ? <LoadingComments /> : <Comment articleId={article.id} comments={comments} />}
    </div>
  );
};

export default Article;

const generateSharingModeText = (state: ShareButtonState) => {
  switch (state) {
    case "link-copied":
      return "Lien copié";
    case "email-sent":
      return "E-mail créé";
    case "twitter-shared":
      return "Tweet créé";
    case "whatsapp-shared":
      return "Message WhatsApp créé";
    case "facebook-shared":
      return "Post Facebook créé";
  }
};
