type Props = {
  withHeadings: boolean;
  content: any;
};

const Table: React.FC<Props> = ({ withHeadings, content }) => {
  if (withHeadings) {
    return (
      <table className="table w-auto mx-auto my-4">
        <thead>
          <tr>
            {content[0].map((e: string, index: number) => (
              <th
                dangerouslySetInnerHTML={{ __html: e }}
                key={index}
                className="border-2 border-green bg-light-green px-8 py-1 text-light-beige"
              ></th>
            ))}
          </tr>
        </thead>
        <tbody>
          {content.slice(1).map((line: string[], lineIndex: number) => {
            return (
              <tr key={lineIndex}>
                {line.map((cell: string, cellIndex: number) => (
                  <td dangerouslySetInnerHTML={{ __html: cell }} key={cellIndex} className="border-2 border-green px-8 py-1"></td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
  return (
    <table className="table w-auto mx-auto my-4">
      <tbody>
        {content.map((line: string[], lineIndex: number) => {
          return (
            <tr key={lineIndex}>
              {line.map((cell: string, cellIndex: number) => (
                <td dangerouslySetInnerHTML={{ __html: cell }} key={cellIndex} className="border-2 border-green px-8 py-1"></td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
