const LoadingComments: React.FC = () => {
  return (
    <div className="w-11/12 lg:w-1/2 mx-auto mt-20">
      <div className="max-w-screen-md mx-auto">
        <div className="bg-green py-2 mb-4">
          <p className="font-garamond text-beige text-center text-2xl font-bold">Commentaires</p>
        </div>
        <div className="mt-4 bg-beige w-full h-24 animate-pulse"></div>
        <div className="mt-4 bg-beige w-full h-24 animate-pulse"></div>
        <div className="mt-4 bg-beige w-full h-24 animate-pulse"></div>
        <div className="mt-4 bg-beige w-full h-60 animate-pulse"></div>
      </div>
    </div>
  );
};

export default LoadingComments;
