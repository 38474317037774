import { useCallback, useContext, useEffect, useState } from "react";
import { ArticlesContext } from "../../App";
import { getCurrentPageData } from "./utils/getCurrentPageData";
import ListingLongCard from "../../components/Card/ListingLongCard";
import { getDate } from "../Article/utils/getDate";
import { generatePagination } from "./utils/generatePagination";
import LoadingListing from "../Loading/LoadingListing";
import { filterArticles } from "./utils/filterArticles";
import { getUniqueAuthors } from "./utils/getUniqueAuthors";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getCategoriesNames } from "../../components/utils/getCategoriesNames";
import { Dropdown } from "../../components/Dropdown/Dropdown";
import { getCategoryNameFromSlug } from "../../components/utils/getCategoryNameFromSlug";
import { generateSlug } from "../../components/Card/utils/generateSlug";
import DropdownItem from "../../components/Dropdown/DropdownItem";

const Listing: React.FC = () => {
  // Get the potential category from URL and load it
  const [searchParams] = useSearchParams();
  const searchParamCategory = searchParams.get("category");
  const searchParamAuthor = searchParams.get("author");

  const location = useLocation();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState<number>(1); // Used for pagination
  const [search, setSearch] = useState<string>(""); // Search input
  const [searchAuthor, setSearchAuthor] = useState<string>(searchParamAuthor !== null ? searchParamAuthor : "S_ALL"); // Author select
  const [searchCategory, setSearchCategory] = useState<string>(searchParamCategory !== null ? searchParamCategory : "S_ALL"); // Category select
  const [displayResetButton, setDisplayResetButton] = useState<boolean>(searchParamCategory !== null ? true : false); // If we should display the reset filter button

  const shouldShowResetButton = useCallback(() => {
    if (search === "" && searchAuthor === "S_ALL" && searchCategory === "S_ALL") {
      setDisplayResetButton(false);
    } else {
      setDisplayResetButton(true);
    }
  }, [search, searchAuthor, searchCategory]);

  const updateSearch = useCallback(
    (e: string) => {
      setSearch(e);
      setCurrentPage(1);
      shouldShowResetButton();
    },
    [shouldShowResetButton]
  );

  const updateAuthor = useCallback(
    (e: string) => {
      setSearchAuthor(e);
      setCurrentPage(1);
      shouldShowResetButton();

      if (e !== "S_ALL") {
        // update the author in the URL with react-router-dom
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("author", e);
        navigate({ search: searchParams.toString() });
      } else {
        // remove the author from the URL with react-router-dom
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete("author");
        navigate({ search: searchParams.toString() });
      }
    },
    [location.search, navigate, shouldShowResetButton]
  );

  const updateCategory = useCallback(
    (e: string) => {
      setSearchCategory(e);
      setCurrentPage(1);
      shouldShowResetButton();

      if (e !== "S_ALL") {
        // update the category in the URL with react-router-dom
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("category", e);
        navigate({ search: searchParams.toString() });
      } else {
        // remove the category from the URL with react-router-dom
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete("category");
        navigate({ search: searchParams.toString() });
      }
    },
    [location.search, navigate, shouldShowResetButton]
  );

  const resetFilters = () => {
    setSearch("");
    setSearchAuthor("S_ALL");
    setSearchCategory("S_ALL");
    setCurrentPage(1);
    shouldShowResetButton();

    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("category");
    searchParams.delete("author");
    navigate({ search: searchParams.toString() });
  };

  useEffect(() => {
    shouldShowResetButton();
  }, [updateSearch, updateAuthor, updateCategory, shouldShowResetButton]);

  const data = useContext(ArticlesContext);
  if (!data?.articles || !data?.categories || !data?.articles.length || !data?.categories.length) return <LoadingListing />;

  const articles: Article[] = data.articles;
  const categories: Category[] = data.categories;

  return (
    <>
      <div className="bg-beige py-5">
        <div className="w-11/12 mx-auto">
          <div className="max-w-screen-md mx-auto">
            <p className="font-bold text-green font-garamond text-lg">Recherche : </p>
            <input
              value={search}
              type="text"
              placeholder="Rechercher un article"
              className="w-full border-2 border-green p-2 bg-white placeholder:text-green text-green font-semibold placeholder:opacity-60 h-11 rounded-md"
              onChange={(e) => updateSearch(e.target.value)}
            />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 mb-10 mt-2">
              <div>
                <p className="font-bold text-green font-garamond text-lg">Autrice : </p>
                <Dropdown
                  options={generateAuthorsOptions(getUniqueAuthors(articles))}
                  selectedOption={
                    searchAuthor === "S_ALL"
                      ? {
                          value: "S_ALL",
                          searchValue: "S_ALL",
                          content: (
                            <DropdownItem text={"Toutes les autrices"} withBorder={false} withHover={false} withPadding={false} />
                          ),
                        }
                      : {
                          value: searchAuthor,
                          searchValue: searchAuthor,
                          content: (
                            <DropdownItem
                              text={searchAuthor.charAt(0).toUpperCase() + searchAuthor.slice(1)}
                              withBorder={false}
                              withHover={false}
                              withPadding={false}
                            />
                          ),
                        }
                  }
                  onChange={(e) => updateAuthor(e)}
                  defaultLabel={"Toutes les autrices"}
                  className="!px-2 py-2.5"
                />
              </div>
              <div>
                <p className="font-bold text-green font-garamond text-lg">Catégorie : </p>
                <Dropdown
                  options={generateCategoryOptions(categories)}
                  selectedOption={
                    searchCategory === "S_ALL"
                      ? {
                          value: "S_ALL",
                          searchValue: "S_ALL",
                          content: (
                            <DropdownItem
                              text={"Toutes les catégories"}
                              withBorder={false}
                              withHover={false}
                              withPadding={false}
                            />
                          ),
                        }
                      : {
                          value: searchCategory,
                          searchValue: searchCategory,
                          content: (
                            <DropdownItem
                              text={getCategoryNameFromSlug(categories, searchCategory)}
                              withBorder={false}
                              withHover={false}
                              withPadding={false}
                            />
                          ),
                        }
                  }
                  onChange={(e) => updateCategory(e)}
                  defaultLabel={"Toutes les catégories"}
                  className="!px-2 py-2.5"
                />
              </div>
            </div>
            {displayResetButton && (
              <p
                className="text-center bg-green text-light-beige font-medium w-fit mx-auto px-4 py-2 cursor-pointer rounded-md transition-all duration-150 hover:md:opacity-80"
                onClick={resetFilters}
              >
                <i className="fa-solid fa-xmark"></i> Réinitialiser les filtres
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center pt-10">
        <div className="w-11/12">
          <div className="max-w-screen-md mx-auto">
            {getCurrentPageData(currentPage, filterArticles(search, searchAuthor, searchCategory, articles, categories)).map(
              (article: any, index: number) => (
                <div key={index}>
                  <ListingLongCard
                    title={article.title}
                    thumbnail={article.thumbnail}
                    author={article.authors.map((e: any) => e.name).join(", ")}
                    date={getDate(article.created_at)}
                    categories={getCategoriesNames(categories, article.categories)}
                    review={article.review}
                  />
                  <div className="md:w-11/12 h-0.5 bg-green mb-4 opacity-50"></div>
                </div>
              )
            )}
            {filterArticles(search, searchAuthor, searchCategory, articles, categories).length === 0 && (
              <div className="text-center text-green font-bold text-2xl mt-8 font-garamond">
                Aucun article avec ces critères de recherche 🐍
              </div>
            )}
            <div className="mx-auto w-fit mt-8">
              {generatePagination(
                filterArticles(search, searchAuthor, searchCategory, articles, categories).length,
                setCurrentPage,
                currentPage
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;

const generateCategoryOptions = (categories: Category[]) => {
  const options: DropdownOption[] = [
    {
      value: "S_ALL",
      searchValue: "S_ALL",
      content: <DropdownItem text={"Toutes les catégories"} />,
    },
  ];
  categories.forEach((category: Category, index: number) => {
    options.push({
      value: generateSlug(category.name),
      searchValue: generateSlug(category.name),
      content: <DropdownItem text={category.name} withBorder={categories.length !== index + 1} subItem={category.subcategory} />,
    });
  });
  return options;
};

const generateAuthorsOptions = (authors: string[]) => {
  const options: DropdownOption[] = [
    {
      value: "S_ALL",
      searchValue: "S_ALL",
      content: <DropdownItem text={"Toutes les autrices"} />,
    },
  ];
  authors.forEach((author: string, index: number) => {
    options.push({
      value: generateSlug(author),
      searchValue: generateSlug(author),
      content: <DropdownItem text={author} withBorder={authors.length !== index + 1} />,
    });
  });
  return options;
};
