import React from "react";

type Props = {
  text: string;
  withBorder?: boolean;
  withPadding?: boolean;
  withHover?: boolean;
  subItem?: boolean;
};

const DropdownItem: React.FC<Props> = ({ text, withBorder = true, withPadding = true, withHover = true, subItem = false }) => {
  return (
    <div className="block w-full">
      <div
        className={
          (withPadding ? "py-1" : "") +
          (withHover ? " cursor-pointer transition-all duration-150 hover:md:opacity-80" : "") +
          " text-green text-sm"
        }
      >
        <p className={subItem ? "pl-4 opacity-70" : ""}>
          {subItem ? "- " : ""}
          {text}
        </p>
      </div>
      {withBorder && <div className="w-full h-0.5 bg-light-green/50 mt-0.5 rounded-full" />}
    </div>
  );
};

export default DropdownItem;
