type Props = {
  number: number;
  onClick: any;
  currentPage: boolean;
};

const PaginationButton: React.FC<Props> = ({ number, onClick, currentPage }) => {
  return (
    <div
      className={
        (currentPage ? "text-green" : "bg-green text-light-beige") +
        "  border-green border-2 w-8 h-8 m-1 cursor-pointer inline-block transition-all duration-150 hover:md:opacity-80"
      }
      onClick={() => onClick(number)}
    >
      <p className="text-center relative top-0.5">{number}</p>
    </div>
  );
};

export default PaginationButton;
